import { Action, Dispatch } from "redux";
import api from "../../api";
import { MessageBarType } from "@fluentui/react";
import { addMessageStatus } from "../ui/action";

interface TryFindCapacityOptimizationResultAction extends Action
{
    type: "TRY_FIND_CAPACITYOPTIMIZATION_RESULT";
    result: CapacityOptimizationResult[];
}

interface DeliveryForcastAction extends Action
{
    type: "DELIVERY_FORCAST";
    deliveries: DeliveryModelOptimiztion[];
    lookupDate: Date;
    stoneToReplaceDate?: Date;
}




export const saveTrainingDataModel = (data: CapacityOptimizationResult) => async (
    dispatch: Dispatch<any>
) => {
    try {
        await api.CapacityOptimizationApi.AddTrainingData(data);
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Api: "Training data saved"`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "adding training data failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const deliveryForcast = (model: CalculateOptimizationRequestModel) => async (dispatch: Dispatch<any>) => {
    try {
        const {deliveries, lookupDate, stoneToReplaceDate} = await api.CapacityOptimizationApi.deliveryForcast(model);
        const action: DeliveryForcastAction = {
            type: "DELIVERY_FORCAST",
            deliveries, lookupDate, stoneToReplaceDate
        };
        dispatch(action);

      
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Try Find Forcast failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const tryFindCapacityOptimizations = (date: Date, minDeliveryTime: number) => async (
    dispatch: Dispatch<any>
) => {
    try {
        const result = await api.CapacityOptimizationApi.tryFindCapacityOptimizations(date, minDeliveryTime);
        const action: TryFindCapacityOptimizationResultAction = {
            type: "TRY_FIND_CAPACITYOPTIMIZATION_RESULT",
            result: result
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Try Find CapacityOptimization failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export type Actions = 
TryFindCapacityOptimizationResultAction | 
DeliveryForcastAction;