import * as React from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, Dialog, IColumn, Label, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import "./style.css";
import TableHelper from "../utils/TableHelper";
import { deliveryForcast } from "../../state/capacityOptimization/action";
import { RootState } from "../../state";
import { getDeliveryForcast, getDeliveryForcastLookupDate, getDeliveryForcastStoneToReplaceDate } from "../../state/action";
import { formatDate, getServerDate } from "../utils/DateHelper";

interface Props {
    traningModel: CapacityOptimizationResult;
    optimizationOptions: CapacityOptimizationResult[];
    date: Date;
    saveTrainingModel: (model: CapacityOptimizationResult) => void;
    onDismiss: () => void;
}

const SolutionDialog = ({ traningModel, optimizationOptions: initialOptions, date, saveTrainingModel, onDismiss }: Props) => {
    const deliveries = useSelector((state: RootState) => getDeliveryForcast(state));
    const lookupDate = useSelector((state: RootState) => getDeliveryForcastLookupDate(state));
    const stoneToReplaceDate = useSelector((state: RootState) => getDeliveryForcastStoneToReplaceDate(state));
    const [optimizationOptions, setOptimizationOptions] = useState<(CapacityOptimizationResult)[]>([]);
    const dispatch = useDispatch();

    const getStartInShop = () =>
        {
            const startInShop = deliveries.filter(x => x.type === "StartInShop");
            if(startInShop)
            {
                const departmentDelivery = startInShop[0];
                const mobileDepartmentDelivery = startInShop[1];
                if(mobileDepartmentDelivery)
                {
                    const mobileDepartment = initialOptions.find(x => x.fromDepartment === mobileDepartmentDelivery.id);
                    return {
                        departmentDelivery,
                        mobileDepartment,
                        mobileDepartmentDelivery
                    }
                }
               
            }
            return {
            };
         
        }
    useEffect(() => {
        dispatch(deliveryForcast({
            date,
            departmentId: traningModel.toDepartment,
            mobileService: traningModel.isMobileDepartment,
            repair: traningModel.toStoneChipDeliveryTime,
            replace: traningModel.toWindowDeliveryTime
        }));
    }, [traningModel, date, dispatch]);

    useEffect(() => {
        let items = [...initialOptions];
        const {mobileDepartment} = getStartInShop()
        if(mobileDepartment)
        {
            const startInShopResult: CapacityOptimizationResult = {...mobileDepartment, type: "START_IN_SHOP"}
            items = [startInShopResult, ...initialOptions];
        }
        
        
        setOptimizationOptions(items);
    }, [deliveries, initialOptions]);

    const onSave = useCallback((index: number) => () => {
        const temp = optimizationOptions[index];
        saveTrainingModel(temp);
    }, [optimizationOptions, saveTrainingModel]);

    const onScoreChange = useCallback((index: number) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
        const temp = [...optimizationOptions];
        const result = temp[index];
        temp[index] = { ...result, score: parseFloat(newValue) };
        setOptimizationOptions(temp);
    }, [optimizationOptions]);

    const renderLocationCodes = (department: CapacityOptimizationResult) => (
        <Stack className="currenct-delivery-time" tokens={{ padding: 5, childrenGap: 10 }}>
            <StackItem grow disableShrink>
                <Label>{department.name}</Label>
            </StackItem>
            <StackItem grow disableShrink>
                <Label>{department.toWindowDeliveryTime}/{department.toStoneChipDeliveryTime}</Label>
            </StackItem>
        </Stack>
    );

    const renderLocationCodesFromAndTo = (department: CapacityOptimizationResult) => (
        <Stack className="currenct-delivery-time" tokens={{ padding: 5, childrenGap: 10 }}>
            <StackItem grow disableShrink>
                <Label>{department.fromLocationCode}</Label> <Label>{department.name}</Label>
            </StackItem>
            <StackItem grow disableShrink>
                <Label>{department.fromWindowDeliveryTime}/{department.fromStoneChipDeliveryTime}</Label>
                <Label>{department.toWindowDeliveryTime}/{department.toStoneChipDeliveryTime}</Label>
            </StackItem>
        </Stack>
    );

    const renderLocationCodesFromAndToDeliveries = (fromDepartment: CapacityOptimizationResult, toDepartment: CapacityOptimizationResult, fromDelivery: DeliveryModelOptimiztion, toDelivery: DeliveryModelOptimiztion) => (
        <Stack className="currenct-delivery-time" tokens={{ padding: 5, childrenGap: 10 }}>
            <StackItem grow disableShrink>
                <Label>{fromDepartment.fromLocationCode}</Label> <Label>{toDepartment.name}</Label>
            </StackItem>
            <StackItem grow disableShrink>
                <Label>{fromDelivery.replace}/{fromDelivery.repair}</Label>
                <Label>{toDelivery.replace}/{toDelivery.repair}</Label>
            </StackItem>
        </Stack>
    );
    

    const columns: IColumn[] = [
        TableHelper.col("currenctDeliveryTime", "Nuværende Leveringstid", 170, 170, (e: CapacityOptimizationResult) => {
            if (e.type === "START_IN_SHOP") {
            
                return renderLocationCodesFromAndTo(e);
            }
            if (e.type === "MOVE_WORKER") {
                return e.fromLocationCode === e.toLocationCode ? renderLocationCodes(e) : renderLocationCodesFromAndTo(e);
            }
        }),
        TableHelper.col("suggesting", "Forslag til optimering", 210, 210, (e: CapacityOptimizationResult) => {
            if (e.type === "START_IN_SHOP") return <Label>Start inde</Label>;
            if (e.type === "MOVE_WORKER") {
                return e.toLocationCode === e.fromLocationCode ? <Label>1 rudeskift konverteres til stenslag</Label> : <Label>1 Montør fra {e.fromLocationCode} til {e.name}</Label>;
            }
        }),
        TableHelper.col("newDeliveryTime", "Leveringstid efter ressourceflytning", 240, 240, (e: CapacityOptimizationResult) => {
            if (e.type === "START_IN_SHOP") {
                const { departmentDelivery, mobileDepartmentDelivery} = getStartInShop();
                return departmentDelivery && mobileDepartmentDelivery ? renderLocationCodesFromAndToDeliveries(e, e, mobileDepartmentDelivery, departmentDelivery) : null;
            }
            if (e.type === "MOVE_WORKER") {
                if (e.fromLocationCode === e.toLocationCode) {
                    const delivery = deliveries.find(x => x.type === "ChangeWindowToStonechipSlots");
                    return delivery ? (
                        <Stack className="currenct-delivery-time" tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem grow disableShrink>
                                <Label>{e.name}</Label>
                            </StackItem>
                            <StackItem grow disableShrink>
                                <Label>{delivery.replace}/{delivery.repair}</Label>
                            </StackItem>
                        </Stack>
                    ) : null;
                }
                const toDepartment = deliveries.find(x => x.id === e.toDepartment && x.type === "MoveWorker");
                const fromDepartment = deliveries.find(x => x.id === e.fromDepartment && x.type === "MoveWorker");
                console.log(toDepartment,fromDepartment);
                return toDepartment && fromDepartment ? renderLocationCodesFromAndToDeliveries(e, e, fromDepartment, toDepartment) : null;
            }
        }),
        TableHelper.col("Rating", "Rating", 80, 80, (e: CapacityOptimizationResult, index: number) => {
            return <Label><TextField type="number" onChange={onScoreChange(index)} value={e.score.toFixed(2).toString()} /></Label>;
        }),
        TableHelper.col("", "", 140, 140, (e: CapacityOptimizationResult, index: number) => (
            <div className="capacityListBtn">
                <PrimaryButton onClick={onSave(index)}>gem</PrimaryButton>
            </div>
        )),
    ];

    return (
        <Dialog onDismiss={onDismiss} minWidth={1000} hidden={false} dialogContentProps={{ showCloseButton: true, title: <>
            Optimering den: {lookupDate ? getServerDate(lookupDate):""}
            <br></br>
            {stoneToReplaceDate? `Lav rudeskift om til stendslag den ${getServerDate(stoneToReplaceDate)}`:""}
        </> }}>
            <DetailsList
                getKey={(e, index: number) => index.toString()}
                compact={false}
                className="hidden-xs assignments"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                columns={columns}
                items={optimizationOptions}
                checkboxVisibility={CheckboxVisibility.hidden}
            />
        </Dialog>
    );
};

export default SolutionDialog;